import {AddressModel} from '../../../../domain/models/checkout/Address.model';
import {BuyerInfoModel} from '../../../../domain/models/checkout/BuyerInfo.model';
import {ContactModel} from '../../../../domain/models/checkout/Contact.model';
import {CustomFieldModel} from '../../../../domain/models/checkout/CustomField.model';
import {CheckoutSettingsModel} from '../../../../domain/models/checkout/checkoutSettings/CheckoutSettings.model';
import {
  AddressTarget,
  CustomerDetailsFormOverrides,
  CustomerDetailsFormSection,
  CustomerDetailsFormValues,
  FormFieldTarget,
  FormHeaderId,
  FullNameTarget,
} from '../../../../types/checkoutApp.types';
import {DEFAULT_COUNTRY} from '../../constants';
import {
  getDeliveryCountriesOverrides,
  getExcludeGoogleAutoCompleteOverrides,
  getSettingsOverrides,
  mergeOverrides,
  getAddressFormInitialState,
  getContactFormInitialState,
} from '../form.utils';
import {FormValues} from '@wix/form-viewer';
import {ExtendedFieldsFragment} from '../../../../gql/graphql';
import {UserExtendedFields, isExtendedFieldNamespacesIsUserFields} from '../../../../domain/utils/types.util';

export function getCustomerDetailsFormInitialState({
  checkoutSettings,
  address,
  buyerInfo,
  contact,
  customField,
  extendedFields,
  shouldSubscribe,
  isFastFlow,
  doesFormHaveSubscription,
}: {
  checkoutSettings: CheckoutSettingsModel;
  address?: AddressModel;
  initialAddress?: AddressModel;
  buyerInfo?: BuyerInfoModel;
  contact?: ContactModel;
  customField?: CustomFieldModel;
  extendedFields?: ExtendedFieldsFragment;
  shouldSubscribe?: boolean | null;
  isFastFlow: boolean;
  doesFormHaveSubscription: boolean;
}): CustomerDetailsFormValues {
  let country;
  const enabledDeliveryCountries = checkoutSettings.enabledDeliveryCountries;
  if (!enabledDeliveryCountries?.length || enabledDeliveryCountries.includes(DEFAULT_COUNTRY)) {
    country = address?.country ?? DEFAULT_COUNTRY;
  } else {
    country = address?.country ?? enabledDeliveryCountries[0];
  }

  return {
    ...(buyerInfo ? getEmailFormInitialState(buyerInfo) : {}),
    ...getContactFormInitialState({
      contact,
      country,
    }),
    ...(extendedFields ? getExtendedFieldsFromCheckout(extendedFields) : {}),
    ...getAdditionalInfoFormInitialState(customField),
    ...getAddressFormInitialState(address, country),
    ...getSubscriptionCheckboxInitialState(isFastFlow, doesFormHaveSubscription, shouldSubscribe),
  };
}

function getEmailFormInitialState(buyerInfo: BuyerInfoModel): FormValues {
  return {
    email: buyerInfo.email ?? '',
  };
}

export function validateEmailAddress(email: string): boolean {
  const emailRegex =
    /^(?!.*\.\.)[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*\.[a-zA-Z]{2,}$/;
  return emailRegex.test(email);
}

function getSubscriptionCheckboxInitialState(
  isFastFlow: boolean,
  doesFormHaveSubscription: boolean,
  shouldSubscribe?: boolean | null
): FormValues {
  return shouldSubscribe !== null && doesFormHaveSubscription && !isFastFlow
    ? {
        [FormFieldTarget.subscribe]: shouldSubscribe ?? /*istanbul ignore next*/ false,
      }
    : {};
}

export function getEmailFromEmailFormValues(emailFormValues: FormValues): string {
  return emailFormValues.email as string;
}

export function getSubscriptionFromFormValues(formValues: FormValues): boolean {
  return formValues[FormFieldTarget.subscribe] as boolean;
}

function getAdditionalInfoFormInitialState(customField?: CustomFieldModel): FormValues {
  return {
    additional_info_1: customField?.value ?? '',
  };
}

export function getCustomFieldFromAdditionalInfoFormValues(additionalInfoFormValues: FormValues): string {
  return (additionalInfoFormValues.additional_info_1 as string) ?? undefined;
}

function getHeadersOverrides({
  isShippingFlow,
  isMember,
  isFastFlow,
}: {
  isShippingFlow: boolean;
  isMember: boolean;
  isFastFlow?: boolean;
}): CustomerDetailsFormOverrides {
  return {
    [FormHeaderId.customer_details_header]: {
      hidden: true,
    },
    [FormHeaderId.shipping_details_header]: {
      hidden: !isShippingFlow || isMember || isFastFlow,
    },
  };
}

function getCheckoutFlowsOverrides({
  isShippingFlow,
  isMember,
  formSections,
  shouldTestNewSubscriptionCheckboxLocation,
}: {
  isShippingFlow: boolean;
  isMember: boolean;
  formSections: CustomerDetailsFormSection[];
  shouldTestNewSubscriptionCheckboxLocation?: boolean;
}): CustomerDetailsFormOverrides {
  /*istanbul ignore next*/
  const additionalInfoSectionOverride = !formSections.includes(CustomerDetailsFormSection.additionalInfo)
    ? {[FormFieldTarget.additional_info_1]: {hidden: true, required: false}}
    : {};

  /*istanbul ignore next*/
  const subscriptionSectionOverride = !formSections.includes(CustomerDetailsFormSection.subscription)
    ? {[FormFieldTarget.subscribe]: {hidden: true, required: false}}
    : {};

  /*istanbul ignore next*/
  const contactSectionOverride = !formSections.includes(CustomerDetailsFormSection.contact)
    ? {
        [FormFieldTarget.full_name]: {
          [FullNameTarget.first_name]: {hidden: true, required: false},
          [FullNameTarget.last_name]: {hidden: true, required: false},
        },
        [FormFieldTarget.phone]: {hidden: true, required: false},
        [FormFieldTarget.company_name]: {hidden: true, required: false},
      }
    : {};

  const emailSectionOverride =
    isMember || !formSections.includes(CustomerDetailsFormSection.email) || shouldTestNewSubscriptionCheckboxLocation
      ? {[FormFieldTarget.email]: {hidden: true, required: false}}
      : {};

  const addressSectionOverride =
    !isShippingFlow || /*istanbul ignore next*/ !formSections.includes(CustomerDetailsFormSection.address)
      ? {
          [FormFieldTarget.address]: {
            [AddressTarget.address_line]: {hidden: true, required: false},
            [AddressTarget.address_line_2]: {hidden: true, required: false},
            [AddressTarget.street_name]: {hidden: true, required: false},
            [AddressTarget.street_number]: {hidden: true, required: false},
            [AddressTarget.city]: {hidden: true, required: false},
            [AddressTarget.country]: {hidden: true, required: false},
            [AddressTarget.subdivision]: {hidden: true, required: false},
            [AddressTarget.postal_code]: {hidden: true, required: false},
          },
        }
      : {};

  return mergeOverrides(
    emailSectionOverride,
    contactSectionOverride,
    addressSectionOverride,
    additionalInfoSectionOverride,
    subscriptionSectionOverride
  );
}

export function getCustomerDetailsFormOverrides({
  checkoutSettings,
  excludeGoogleAutoComplete,
  isShippingFlow,
  isMember,
  formSections,
  isFastFlow,
  shouldTestNewSubscriptionCheckboxLocation,
}: {
  checkoutSettings: CheckoutSettingsModel;
  excludeGoogleAutoComplete: boolean;
  isShippingFlow: boolean;
  isMember: boolean;
  formSections?: CustomerDetailsFormSection[];
  isFastFlow?: boolean;
  shouldTestNewSubscriptionCheckboxLocation?: boolean;
}): CustomerDetailsFormOverrides {
  const deliveryCountriesOverrides = getDeliveryCountriesOverrides(checkoutSettings.enabledDeliveryCountries);

  return mergeOverrides(
    getSettingsOverrides({checkoutSettings}),
    deliveryCountriesOverrides,
    getHeadersOverrides({isShippingFlow, isMember, isFastFlow}),
    getExcludeGoogleAutoCompleteOverrides({excludeGoogleAutoComplete}),
    getCheckoutFlowsOverrides({
      isShippingFlow,
      isMember,
      formSections: formSections ?? [
        CustomerDetailsFormSection.email,
        CustomerDetailsFormSection.subscription,
        CustomerDetailsFormSection.contact,
        CustomerDetailsFormSection.address,
        CustomerDetailsFormSection.additionalInfo,
      ],
      shouldTestNewSubscriptionCheckboxLocation,
    })
  );
}

export function getExtendedFieldsFromCheckout(extendedFields?: UserExtendedFields) {
  return extendedFields?.namespaces && isExtendedFieldNamespacesIsUserFields(extendedFields?.namespaces)
    ? extendedFields.namespaces._user_fields
    : /* istanbul ignore next */ undefined;
}

export function getExtendedFieldValuesFromCustomerDetailsForm(
  formValues: FormValues,
  dExtTargets: string[] | null
): FormValues {
  /*istanbul ignore next*/
  if (!dExtTargets) {
    /* istanbul ignore next */
    return {};
  }
  return dExtTargets.reduce(
    /* istanbul ignore next */
    (dExtValues, target) => ({
      ...dExtValues,
      [target]: formValues[target],
    }),
    {}
  );
}
