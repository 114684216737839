import {CheckoutSettingsModel} from '../models/checkout/checkoutSettings/CheckoutSettings.model';
import {CheckoutModel} from '../models/checkout/Checkout.model';
import {hasGiftCardItem} from './hasGiftCardItem';

export const shouldShowGiftCardSection = ({
  checkoutSettings,
  checkout,
}: {
  checkoutSettings: CheckoutSettingsModel;
  checkout: CheckoutModel;
}): boolean => {
  return (
    checkoutSettings?.isGiftCardEnabled &&
    checkoutSettings?.isGiftCardSupported &&
    !checkout.customSettings?.hideGiftCard &&
    !checkout.hasSubscriptionItems &&
    !hasGiftCardItem(checkout)
  );
};
