import {COUNTRIES_WITH_VAT, COUNTRIES_WITH_VAT_OLD} from '../../components/Checkout/constants';
import {Experiments} from '@wix/yoshi-flow-editor';
import {SPECS} from '../../common/constants';

export function isCountryWithVat(country?: string, experiments?: Experiments) {
  if (experiments?.enabled(SPECS.ShowVatIdOnBillingForIsrael)) {
    return country && COUNTRIES_WITH_VAT.includes(country);
  } else {
    return country && COUNTRIES_WITH_VAT_OLD.includes(country);
  }
}
