import {CustomSettingsFragment} from '../../../gql/graphql';

export class CustomSettingsModel {
  public hideGiftCard: boolean;
  public hideCoupon: boolean;
  public disableManualPayment: boolean;
  public hidePolicyAgreementCheckbox: boolean;

  constructor(customSettings?: CustomSettingsFragment | null) {
    this.hideGiftCard = customSettings?.lockGiftCard ?? false;
    this.hideCoupon = customSettings?.lockCouponCode ?? false;
    this.disableManualPayment = customSettings?.disabledManualPayment ?? false;
    this.hidePolicyAgreementCheckbox = customSettings?.disabledPolicyAgreementCheckbox ?? false;
  }
}
