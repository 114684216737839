import {ShippingOptionFragment} from '../../../gql/graphql';

export class ScheduledDeliveryOptionModel {
  public code: string;
  public deliveryTime: string;
  constructor(option: ShippingOptionFragment) {
    this.code = option.code!;
    this.deliveryTime = option.logistics?.deliveryTime ?? /* istanbul ignore next */ '';
  }
}
