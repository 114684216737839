import {AddressWithContactModel} from '../models/checkout/AddressWithContact.model';
import {AddressModel} from '../models/checkout/Address.model';
import {StreetAddressModel} from '../models/checkout/StreetAddress.model';
import {ContactModel} from '../models/checkout/Contact.model';
import {getCountryByKey, getCountryByShortKey} from '@wix/wixstores-locale-dataset-adapter';
import {getFullSubdivisionCode} from './localeDataset.util';
import {getCommonVatId, isCountryWithVAT} from './vat.util';
import {Address as MemberDetailsInfo, Street as MemberStreet} from '@wix/ambassador-addresses-api-v1-address/types';
import {FullAddressContactDetailsFragment, VatIdFragment} from '../../gql/graphql';
import {AddressesFieldMasks} from '../../components/Checkout/constants';
import {ApiAddress} from '../../types/checkoutApp.types';
import {CheckoutModel} from '../models/checkout/Checkout.model';
import {Experiments} from '@wix/yoshi-flow-editor';
import {isManualPaymentSelected, isPayPalSelected} from './cashier.utils';
import {SPECS} from '../../common/constants';

export const mapToAddressWithContactModel = (memberAddress: MemberDetailsInfo): AddressWithContactModel => {
  return new AddressWithContactModel({
    addressesServiceId: memberAddress.id,
    address: new AddressModel({
      streetAddress: isStreet(memberAddress.street)
        ? new StreetAddressModel({
            name: memberAddress.street?.name,
            number: memberAddress.street?.number,
          })
        : undefined,
      addressLine: !isStreet(memberAddress.street) ? memberAddress.addressLine1 : undefined,
      addressLine2: memberAddress.addressLine2,
      city: memberAddress.city,
      postalCode: memberAddress.zipCode,
      country: memberAddress.country ? getCountryByKey(memberAddress.country)?.shortKey : undefined,
      subdivision:
        memberAddress.subdivision && memberAddress.country
          ? getFullSubdivisionCode(memberAddress.country, memberAddress.subdivision)
          : undefined,
    }),
    contactDetails: new ContactModel({
      firstName: memberAddress.fullName!.firstName,
      lastName: memberAddress.fullName!.lastName,
      company: memberAddress.company,
      phone: memberAddress.phoneNumber,
      vatId: memberAddress.taxInfo ? getCommonVatId(memberAddress.taxInfo) : undefined,
    }),
  });
};

const isStreet = (street?: MemberStreet): boolean => {
  return !!street?.name || !!street?.number;
};

export const mapContactModelToContactDetails = (contact?: ContactModel): FullAddressContactDetailsFragment => {
  return contact
    ? {
        firstName: contact.firstName ?? /* istanbul ignore next */ '',
        lastName: contact.lastName ?? /* istanbul ignore next */ '',
        ...(contact.phone ? {phone: contact.phone} : /* istanbul ignore next */ {}),
        ...(contact.company ? {company: contact.company} : /* istanbul ignore next */ {}),
        ...(contact.vatId ? /* istanbul ignore next */ ({vatId: contact.vatId} as VatIdFragment) : {}),
      }
    : /* istanbul ignore next */ {};
};

export const mapAddressModelToApiAddress = (address?: AddressModel): ApiAddress => {
  const streetAddress = address?.streetAddress
    ? {
        name: address.streetAddress.name ?? /* istanbul ignore next: test forms */ undefined,
        number: address.streetAddress.number ?? /* istanbul ignore next: test forms */ undefined,
      }
    : undefined;

  return address
    ? {
        country: address.country,
        city: address.city,
        ...(address.subdivision ? {subdivision: address.subdivision} : {}),
        ...(address.postalCode ? {postalCode: address.postalCode} : /* istanbul ignore next: test forms */ {}),
        ...(address.addressLine ? {addressLine: address.addressLine} : {}),
        ...(streetAddress ? {streetAddress} : {}),
        ...(address.addressLine2 ? {addressLine2: address.addressLine2} : {}),
      }
    : /* istanbul ignore next */ {};
};

export const mapToUpdateMemberAddressRequest = (
  contactDetails: FullAddressContactDetailsFragment,
  apiAddress: ApiAddress,
  addressesServiceId?: string
): MemberDetailsInfo => {
  const addressWithContactModel = new AddressWithContactModel({
    contactDetails,
    address: apiAddress,
    addressesServiceId,
  });
  const {contact, address} = addressWithContactModel;

  return {
    id: addressesServiceId ?? null,
    fullName: {firstName: contact.firstName, lastName: contact.lastName},
    phoneNumber: contact.phone ?? null,
    company: contact.company ?? null,
    addressLine1:
      address.streetAddress?.name && address.streetAddress?.number
        ? `${address.streetAddress.name} ${address.streetAddress.number}`
        : address?.addressLine ?? /* istanbul ignore next */ null,
    addressLine2: address?.addressLine2 ?? null,
    street: {
      name: (address.streetAddress?.name ?? null) as unknown as string,
      number: (address.streetAddress?.number ?? null) as unknown as string,
      apt: null,
    },
    country: address?.country ? getCountryByShortKey(address.country).key : /* istanbul ignore next */ null,
    zipCode: address?.postalCode ?? /* istanbul ignore next */ null,
    city: address?.city ?? /* istanbul ignore next */ null,
    subdivision: address?.subdivision
      ? address.subdivision.substring(address.subdivision.indexOf('-') + 1)
      : /* istanbul ignore next */ null,
    taxInfo: {
      id: (contact.vatId?.id ?? null) as unknown as string,
      type: (contact.vatId?.type ?? null) as unknown as string,
    },
  } as MemberDetailsInfo;
};

export const isBillingEqualToShipping = (
  billingAddressAndContact?: AddressWithContactModel,
  shippingAddressAndContact?: AddressWithContactModel
): boolean => {
  const shippingNoGeo: AddressWithContactModel | undefined = shippingAddressAndContact
    ? {
        ...shippingAddressAndContact,
        address: {
          ...shippingAddressAndContact.address,
          geocode: undefined,
        },
      }
    : undefined;
  return JSON.stringify(shippingNoGeo) === JSON.stringify(billingAddressAndContact);
};

export const getFieldMasksFromAddress = (address: MemberDetailsInfo): AddressesFieldMasks[] => {
  const flatAddress = {
    [AddressesFieldMasks.firstName]: address.fullName?.firstName,
    [AddressesFieldMasks.lastName]: address.fullName?.lastName,
    [AddressesFieldMasks.country]: address.country,
    [AddressesFieldMasks.subdivision]: address.subdivision,
    [AddressesFieldMasks.city]: address.city,
    [AddressesFieldMasks.zipCode]: address.zipCode,
    [AddressesFieldMasks.phoneNumber]: address.phoneNumber,
    [AddressesFieldMasks.company]: address.company,
    [AddressesFieldMasks.addressLine1]: address.addressLine1,
    [AddressesFieldMasks.addressLine2]: address.addressLine2,
    [AddressesFieldMasks.streetName]: address.street?.name,
    [AddressesFieldMasks.streetNumber]: address.street?.number,
    [AddressesFieldMasks.taxId]: address.taxInfo?.id,
    [AddressesFieldMasks.taxType]: address.taxInfo?.type,
  };
  const fieldMasks = Object.keys(flatAddress).filter(
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    (mask: string) => !!(flatAddress as any)[mask]
  ) as AddressesFieldMasks[];
  return fieldMasks.concat(AddressesFieldMasks.setAsDefault);
};

export function isAddressModelEmpty(model?: AddressModel) {
  return isModelEmpty(model);
}

export function isContactModelEmpty(model?: ContactModel) {
  return isModelEmpty(model);
}

function isModelEmpty(model?: object) {
  if (!model) {
    return true;
  }

  return Object.values(model).every((v) => v === undefined);
}

export const wouldHideBillingDetailsSection = (
  checkout: CheckoutModel,
  activePaymentId?: string,
  experiments?: Experiments
): boolean => {
  const isBillingCountryExists = !!checkout.billingInfo?.address.country;
  const isShippingCountryExists = !!checkout.shippingDestination?.address.country;

  const isCountryAbsent = !isBillingCountryExists && !isShippingCountryExists;
  const isManualOrPayPalSelected = isManualPaymentSelected(activePaymentId) || isPayPalSelected(activePaymentId);
  const isDigitalFlow = !checkout.hasShippableItems;

  if (isCountryAbsent || isDigitalFlow) {
    return false;
  }
  return !isCountryWithVAT(checkout, experiments) && isManualOrPayPalSelected;
};

export const shouldHideBillingDetailsSection = (
  checkout: CheckoutModel,
  experiments: Experiments,
  activePaymentId?: string
): boolean => {
  return experiments.enabled(SPECS.HideBillingFormForPayPalAndManualNotBrazil)
    ? wouldHideBillingDetailsSection(checkout, activePaymentId, experiments)
    : false;
};
