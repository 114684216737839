import {
  Payments,
  StoreCheckoutSettings,
  LocalDeliveryByZipCodeFlag,
  Destination,
} from '@wix/wixstores-graphql-schema-node';
import {query} from './getCheckoutSettings.graphql';
import {BaseApi, GraphQLOperations, RemoteSourceTypes} from '@wix/wixstores-client-storefront-sdk';
import {StoreCheckoutSettingsAndPayments} from '../../utils/types.util';

export class CheckoutSettingsApi extends BaseApi {
  public async getCheckoutSettings(): Promise<StoreCheckoutSettingsAndPayments> {
    const response = await this.fetch<
      Record<string, never>,
      {
        checkoutSettings: StoreCheckoutSettings;
        payments: Payments;
        localDeliveryByZipCodeFlag: LocalDeliveryByZipCodeFlag;
        destinations: Destination[];
      }
    >(
      {
        query,
        variables: {},
        operationName: GraphQLOperations.GetCheckoutSettings,
        source: 'WixStoresWebClient',
      },
      RemoteSourceTypes.NodeReadWrite
    );

    return {
      checkoutSettings: response.data.checkoutSettings,
      payments: response.data.payments,
      localDeliveryByZipCodeFlag: response.data.localDeliveryByZipCodeFlag,
      destinations: response.data.destinations,
    };
  }
}
