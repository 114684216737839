export enum SlotId {
  AfterLineItemsSummaryCheckoutSlot = 'checkout:summary:lineItems:after',
  AfterLineItems2SummaryCheckoutSlot = 'checkout:summary:lineItems:after2',
  AfterSummaryCheckoutSlot = 'checkout:summary:after',
  BeforeStepsCheckoutSlot = 'checkout:steps:before',
  BeforeSummaryCheckoutSlot = 'checkout:summary:before',
  BeforeTotalsBreakdownSummaryCheckoutSlot = 'checkout:summary:totalsBreakdown:before',
  DeliveryMethodStep = 'checkout:steps:deliveryMethod',
  HeaderCheckoutSlot = 'checkout:header',
  TopCheckoutSlot = 'checkout:top',
  AfterPolicies = 'checkout:policies:after-1',
}
