/* istanbul ignore file */
import {GeoCodeFragment} from '../../../gql/graphql';

export class GeoCodeModel {
  public latitude?: number;
  public longitude?: number;
  constructor(geocode: GeoCodeFragment) {
    this.latitude = geocode.latitude ?? /* istanbul ignore next */ undefined;
    this.longitude = geocode.longitude ?? /* istanbul ignore next */ undefined;
  }
}
