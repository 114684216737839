import {BuyerInfoFragment} from '../../../gql/graphql';

export class BuyerInfoModel {
  public email?: string;
  public contactId?: string;
  constructor(buyerInfo?: BuyerInfoFragment | null) {
    this.email = buyerInfo?.email ?? undefined;
    this.contactId = buyerInfo?.contactId ?? undefined;
  }
}
