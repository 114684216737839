import {PriceModel} from './Price.model';
import {ScheduledDeliveryOptionModel} from './ScheduledDeliveryOption.model';
import {ShippingOptionFragment} from '../../../gql/graphql';
import {convertToDateTimeByTimezone, dateKeyFromDateTime, dateTimeToTimeString} from '../../utils/dateTime.utils';
import {AddressModel} from './Address.model';

export class ShippingOptionModel {
  public code: string;
  public title: string;
  public deliveryTime: string;
  public instructions?: string;
  public pickupAddress?: AddressModel;
  public initialPrice: PriceModel;
  public price: PriceModel;
  public hasTimeSlots?: boolean;
  public scheduledDeliveryOptions?: ScheduledDeliveryOptionModel[];
  public timeSlotDays?: {[date: string]: TimeSlotOption[]};
  public hasDifferentPricedTimeSlots?: boolean;

  constructor(
    shippingOption: ShippingOptionFragment,
    aggregatedDeliveryOptions?: ShippingOptionFragment[],
    selectedShippingOptionId?: string,
    options?: {timezone?: string}
  ) {
    const currentShippingOption =
      aggregatedDeliveryOptions?.find(({code}) => code === selectedShippingOptionId) ?? shippingOption;

    this.code = shippingOption.code!;
    this.title = currentShippingOption.title!;
    this.deliveryTime = currentShippingOption.logistics?.deliveryTime ?? '';
    this.instructions = currentShippingOption.logistics?.instructions ?? undefined;
    this.pickupAddress = currentShippingOption.logistics?.pickupDetails?.address
      ? new AddressModel(currentShippingOption.logistics.pickupDetails.address)
      : undefined;
    this.initialPrice = new PriceModel(shippingOption.cost?.price);
    this.price = new PriceModel(currentShippingOption.cost?.price);

    this.hasTimeSlots = Boolean(shippingOption?.logistics?.deliveryTimeSlot) && Boolean(aggregatedDeliveryOptions);
    if (this.hasTimeSlots) {
      this.timeSlotDays = getTimeSlotDays(aggregatedDeliveryOptions!, options?.timezone);
      this.hasDifferentPricedTimeSlots = Boolean(
        Object.keys(this.timeSlotDays)
          .flatMap((day) => this.timeSlotDays?.[day])
          .find((option) => option?.price.amount !== this.price.amount)
      );
    } else {
      this.scheduledDeliveryOptions = aggregatedDeliveryOptions?.map(
        (option) => new ScheduledDeliveryOptionModel(option)
      );
    }
  }
}

function getTimeSlotDays(aggregatedDeliveryOptions: ShippingOptionFragment[], timezone?: string) {
  const timeSlotOptions = aggregatedDeliveryOptions
    .filter((option) => !!option.logistics?.deliveryTimeSlot?.from)
    .map((option) => new TimeSlotOption(option, timezone));

  return timeSlotOptions.reduce<{[dateString: string]: TimeSlotOption[]}>((acc, option) => {
    acc[option.dateKey] = acc[option.dateKey] || [];
    acc[option.dateKey].push(option);
    return acc;
  }, {});
}

export class TimeSlotOption {
  public id: string;
  public dateAtStartOfDay: Date;
  public price: PriceModel;
  public dateMillis: number;
  public dateKey: string;
  public timeString: string;

  constructor(option: ShippingOptionFragment, timezone?: string) {
    this.id = option.code!;
    const from = +option.logistics!.deliveryTimeSlot!.from!;
    const to = +option.logistics!.deliveryTimeSlot!.to!;
    this.dateMillis = from;
    const dateTime = convertToDateTimeByTimezone(this.dateMillis, timezone);
    this.dateAtStartOfDay = new Date(dateTime.year, dateTime.month - 1, dateTime.day);
    this.dateKey = dateKeyFromDateTime(dateTime);
    this.price = new PriceModel(option.cost?.price);

    if (to !== from) {
      const endTimeString = convertToDateTimeByTimezone(to, timezone);
      this.timeString = `${dateTimeToTimeString(dateTime)} - ${dateTimeToTimeString(endTimeString)}`;
    } else {
      this.timeString = dateTimeToTimeString(dateTime);
    }
  }
}
