import {PriceModel} from './Price.model';
import {AdditionalFeeFragment} from '../../../gql/graphql';

export class AdditionalFeeModel {
  public code: string;
  public name: string;
  public priceBeforeTax: PriceModel;
  constructor(additionalFee: AdditionalFeeFragment) {
    this.code = additionalFee.code!;
    this.name = additionalFee.name!;
    this.priceBeforeTax = new PriceModel(additionalFee.priceBeforeTax);
  }
}
