import {DateTime} from 'luxon';

export const convertToDateTimeByTimezone = (millis: number, timezone?: string): DateTime =>
  DateTime.fromMillis(millis, {zone: 'UTC'}).setZone(timezone);

export const dateKeyFromDateTime = (dateTime: DateTime): string => `${dateTime.day}.${dateTime.month}.${dateTime.year}`;

export const dateKeyFromDate = (date: Date): string => {
  return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;
};

export const dateTimeToTimeString = (dateTime: DateTime) => dateTime.toLocaleString(DateTime.TIME_SIMPLE);
