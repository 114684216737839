// eslint-disable-next-line wix-custom-rules/wixstores-file-names
import {ComWixEcommerceValidationsSpiV1TargetNameInOther, ViolationOtherTargetFragment} from '../../../gql/graphql';

export class ViolationOtherTargetModel {
  public name?: ViolationOtherTargetName;
  constructor(otheTarget?: ViolationOtherTargetFragment) {
    this.name = otheTarget?.name ? toViolationOtherTargetName(otheTarget?.name) : /* istanbul ignore next */ undefined;
  }
}

export enum ViolationOtherTargetName {
  default = 'OTHER_DEFAULT',
}

const toViolationOtherTargetName = (otherTargetName: ComWixEcommerceValidationsSpiV1TargetNameInOther) => {
  // eslint-disable-next-line sonarjs/no-small-switch
  switch (otherTargetName) {
    case ComWixEcommerceValidationsSpiV1TargetNameInOther.OTHER_DEFAULT:
      return ViolationOtherTargetName.default;
  }
};
