import {ambassadorWithHeaders} from '../../utils/ambassador.utils';
import {ControllerFlowAPI} from '@wix/yoshi-flow-editor';
import {SiteStore} from '@wix/wixstores-client-storefront-sdk';
import {getCheckoutContent} from '@wix/ambassador-ecom-api-v1-checkout-content-provider/http';
import {CheckoutContentModel} from '../../models/checkout/CheckoutContent.model';

export class ContentService {
  private readonly flowAPI: ControllerFlowAPI;
  private readonly siteStore: SiteStore;
  public readonly currency?: string;
  public readonly contentAppId?: string;
  public readonly contentComponentId?: string;
  public checkoutContent!: CheckoutContentModel;

  constructor({
    siteStore,
    flowAPI,
    currency,
    contentAppId,
    contentComponentId,
  }: {
    flowAPI: ControllerFlowAPI;
    siteStore: SiteStore;
    currency?: string;
    contentAppId?: string;
    contentComponentId?: string;
  }) {
    this.siteStore = siteStore;
    this.flowAPI = flowAPI;
    this.currency = currency;
    this.contentAppId = contentAppId;
    this.contentComponentId = contentComponentId;
  }

  public async fetchCheckoutContent(): Promise<void> {
    if (this.contentAppId && this.contentComponentId) {
      try {
        const {language} = this.flowAPI.translations.config;
        const {data} = await ambassadorWithHeaders(
          getCheckoutContent({appId: this.contentAppId, componentId: this.contentComponentId, languageCode: language}),
          this.siteStore,
          this.flowAPI,
          this.currency
        );
        this.checkoutContent = new CheckoutContentModel(data.checkoutContent);
      } catch (error) {
        this.checkoutContent = new CheckoutContentModel();
      }
    }
  }
}
