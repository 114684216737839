export class CheckboxesService {
  private policyCheckboxChecked?: boolean;
  private digitalPolicyCheckboxChecked?: boolean;
  private subscriptionCheckboxChecked?: boolean;
  private paymentPolicyCheckboxChecked: boolean = false;
  private wasFormSubmitted: boolean = false;

  public togglePolicyCheckboxChecked(checked: boolean): void {
    this.policyCheckboxChecked = checked;
  }

  public togglePaymentPolicyCheckboxChecked(checked: boolean): void {
    this.paymentPolicyCheckboxChecked = checked;
  }

  public toggleDigitalPolicyCheckboxChecked(checked: boolean): void {
    this.digitalPolicyCheckboxChecked = checked;
  }

  public toggleSubscriptionCheckboxChecked(checked: boolean): void {
    this.subscriptionCheckboxChecked = checked;
  }

  public toggleWasFormSubmitted(wasSubmitted: boolean): void {
    this.wasFormSubmitted = wasSubmitted;
  }

  public get isSubscriptionCheckboxCheckedByUser(): boolean | undefined {
    return this.subscriptionCheckboxChecked;
  }

  public get isPolicyCheckboxCheckedByUser(): boolean | undefined {
    return this.policyCheckboxChecked;
  }

  public get isDigitalPolicyCheckboxCheckedByUser(): boolean | undefined {
    return this.digitalPolicyCheckboxChecked;
  }

  public get isPaymentPolicyCheckboxCheckedByUser(): boolean {
    return this.paymentPolicyCheckboxChecked;
  }

  public get wasFormSubmittedByUser(): boolean {
    return this.wasFormSubmitted;
  }
}
