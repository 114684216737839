export class PaymentPolicyModel {
  public subtitle?: string;
  public policy?: string;

  constructor({
    productName,
    consentRequiredPaymentPolicy,
  }: {
    productName?: string;
    consentRequiredPaymentPolicy?: string;
  }) {
    this.subtitle = productName ?? /* istanbul ignore next */ undefined;
    this.policy = consentRequiredPaymentPolicy ?? /* istanbul ignore next */ undefined;
  }
}
