import {DescriptionLineFragment} from '../../../gql/graphql';

export class LineItemOptionModel {
  public value: string;
  public title: string;
  constructor({value, title}: {value: string; title: string}) {
    this.value = value;
    this.title = title;
  }

  public static convertToOptionsList(descriptionLines?: (DescriptionLineFragment | null)[]): LineItemOptionModel[] {
    return (descriptionLines ?? /* istanbul ignore next */ [])
      .filter(
        (descriptionLine) =>
          !!descriptionLine &&
          !!(descriptionLine.colorInfo
            ? descriptionLine.colorInfo?.translated?.trim()
            : descriptionLine.plainText?.translated?.trim())
      )
      .map(
        (descriptionLine) =>
          new LineItemOptionModel({
            title: descriptionLine!.name?.translated || /* istanbul ignore next */ '',
            value: descriptionLine!.colorInfo
              ? descriptionLine!.colorInfo.translated!
              : descriptionLine!.plainText!.translated!,
          })
      );
  }
}
