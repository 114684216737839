import {StreetAddressModel} from './StreetAddress.model';
import {ApiAddress} from '../../../types/checkoutApp.types';
import {GeoCodeModel} from './GeoCode.model';
import {isAddressWithGeoCode} from '../../utils/types.util';

export class AddressModel {
  public streetAddress?: StreetAddressModel;
  public addressLine?: string;
  public addressLine2?: string;
  public city?: string;
  public postalCode?: string;
  public country?: string;
  public subdivision?: string;
  public countryFullname?: string;
  public subdivisionFullname?: string;
  public geocode?: GeoCodeModel;
  constructor(address?: ApiAddress | null) {
    address = address ?? /* istanbul ignore next */ {};
    this.streetAddress = address.streetAddress ? new StreetAddressModel(address.streetAddress) : undefined;
    this.addressLine = address.addressLine ?? /* istanbul ignore next */ undefined;
    this.addressLine2 = address.addressLine2 ?? /* istanbul ignore next */ undefined;
    this.city = address.city ?? /* istanbul ignore next */ undefined;
    this.postalCode = address.postalCode ?? /* istanbul ignore next */ undefined;
    this.countryFullname = address.countryFullname ?? /* istanbul ignore next */ undefined;
    this.subdivisionFullname = address.subdivisionFullname ?? /* istanbul ignore next */ undefined;
    this.country = address.country ?? /* istanbul ignore next */ undefined;
    this.subdivision = address.subdivision ?? /* istanbul ignore next */ undefined;
    this.geocode =
      isAddressWithGeoCode(address) && /* istanbul ignore next */ address.geocode
        ? /* istanbul ignore next */ new GeoCodeModel(address.geocode)
        : /* istanbul ignore next */ undefined;
  }
}
