import {PriceModel} from './Price.model';
import {AppliedDiscountFragment, CouponFragment} from '../../../gql/graphql';

export class AppliedCouponModel {
  public couponType?: string;
  public price: PriceModel;
  public code: string;
  public id: string;
  public name: string;

  constructor(coupon: CouponFragment) {
    this.couponType = coupon.couponType ?? /* istanbul ignore next */ undefined;
    this.price = new PriceModel(coupon.amount);
    this.code = coupon.code!;
    this.id = coupon.id!;
    this.name = coupon.name!;
  }

  public static fromDTO(appliedDiscounts: (AppliedDiscountFragment | null)[] = []): AppliedCouponModel | undefined {
    const coupon = appliedDiscounts.find((discount) => discount?.coupon)?.coupon;
    return coupon ? new AppliedCouponModel(coupon) : /* istanbul ignore next */ undefined;
  }
}
