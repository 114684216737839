import {CheckoutErrorModel, getCouponError, getExternalCarrierError, getGiftCardError} from './CheckoutError.model';
import {LineItemModel} from './LineItem.model';
import {CheckoutErrorCode, ErrorType} from '../../utils/errors';
import {CalculationErrorsFragment, CarrierErrorFragment, FieldViolationFragment} from '../../../gql/graphql';

export class ErrorsModel {
  public hasCalculationErrors: boolean;
  public hasTaxSubdivisionError: boolean;
  public hasTaxZipCodeError: boolean;
  public hasShippingSubdivisionError: boolean;
  public hasShippingZipCodeError: boolean;
  public couponError?: CheckoutErrorModel;
  public giftCardError?: CheckoutErrorModel;
  public noItemsError?: CheckoutErrorModel;
  public externalCarrierError?: CheckoutErrorModel;
  private readonly noShippingExternalError?: CarrierErrorFragment | null;
  /* eslint-disable sonarjs/cognitive-complexity */

  constructor(lineItems: LineItemModel[], calculationErrors?: CalculationErrorsFragment) {
    const hasViolatingField = (
      violations?: (Pick<FieldViolationFragment, 'field' | 'description'> | null)[] | null,
      fieldName?: string,
      description?: string
    ) =>
      !!violations &&
      violations.some((v) => v && v.field === fieldName && (description ? v.description === description : true));

    this.hasShippingSubdivisionError =
      calculationErrors?.carrierErrors?.errors?.some((err) =>
        hasViolatingField(err?.error?.validationError?.fieldViolations, 'shippingDestination.subdivision')
      ) ?? false;

    this.hasTaxSubdivisionError = hasViolatingField(
      calculationErrors?.taxCalculationError?.validationError?.fieldViolations,
      'subdivision',
      'MISSING'
    );

    this.hasShippingZipCodeError =
      calculationErrors?.carrierErrors?.errors?.some((err) =>
        hasViolatingField(err?.error?.validationError?.fieldViolations, 'postalCode')
      ) ?? false;

    this.hasTaxZipCodeError = hasViolatingField(
      calculationErrors?.taxCalculationError?.validationError?.fieldViolations,
      'postalCode',
      'MISSING'
    );

    this.couponError =
      (calculationErrors?.couponCalculationError &&
        new CheckoutErrorModel(getCouponError(calculationErrors?.couponCalculationError))) ??
      /* istanbul ignore next */ undefined;

    this.giftCardError =
      (calculationErrors?.giftCardCalculationError &&
        new CheckoutErrorModel(getGiftCardError(calculationErrors?.giftCardCalculationError))) ??
      /* istanbul ignore next */ undefined;

    const getNoShippingExternalError = () =>
      calculationErrors?.carrierErrors?.errors?.find(
        (error) => error?.error?.applicationError?.code === CheckoutErrorCode.NO_SHIPPING
      );
    this.noShippingExternalError = getNoShippingExternalError();

    this.externalCarrierError =
      (this.noShippingExternalError &&
        new CheckoutErrorModel(getExternalCarrierError(this.noShippingExternalError.error!))) ??
      /* istanbul ignore next */ undefined;

    const isCheckoutEmpty = lineItems.length === 0;
    const areAllItemsOutOfStock = lineItems.every((lineItem) => lineItem.quantity === 0);

    if (isCheckoutEmpty) {
      this.noItemsError = new CheckoutErrorModel({
        code: CheckoutErrorCode.EMPTY_CHECKOUT,
        type: ErrorType.GENERAL,
      });
    } else if (areAllItemsOutOfStock) {
      this.noItemsError = new CheckoutErrorModel({
        code: CheckoutErrorCode.FULL_OUT_OF_STOCK,
        type: ErrorType.GENERAL,
      });
    }

    this.hasCalculationErrors =
      !!calculationErrors?.taxCalculationError ||
      !!calculationErrors?.couponCalculationError ||
      !!calculationErrors?.giftCardCalculationError ||
      !!calculationErrors?.membershipError ||
      !!calculationErrors?.discountsCalculationError ||
      !!calculationErrors?.generalShippingCalculationError ||
      !!calculationErrors?.carrierErrors ||
      !!(
        calculationErrors?.orderValidationErrors?.length &&
        /* istanbul ignore next */ calculationErrors?.orderValidationErrors?.length > 0
      );
  }
}
