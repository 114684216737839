import {ViolationTargetFragment} from '../../../gql/graphql';
import {ViolationOtherTargetModel} from './ViolationOtherTargetModel.model';
import {ViolationLineItemTargetModel} from './ViolationLineItemTargetModel.model';

export class ViolationTargetModel {
  public lineItemTarget?: ViolationLineItemTargetModel;
  public otherTarget?: ViolationOtherTargetModel;
  constructor(target: ViolationTargetFragment) {
    this.lineItemTarget = target?.lineItem ? new ViolationLineItemTargetModel(target.lineItem) : undefined;
    this.otherTarget = target?.other ? new ViolationOtherTargetModel(target.other) : undefined;
  }
}
