import {ITrackEventName, ITrackEventParams} from '@wix/native-components-infra';
import {CheckoutModel} from '../models/checkout/Checkout.model';

const enum PaymentMethodType {
  PAYPAL = 'paypal',
}

const enum EventAction {
  AgreeToTerms = 'Agree To Terms',
}

const origin = 'Stores';
const eventCategory = 'Enhanced Ecommerce - Stores';

export const FastFlowAnalyticsEventParams = ['AddPaymentInfo', {option: PaymentMethodType.PAYPAL, origin}] as [
  ITrackEventName,
  ITrackEventParams
];

export const AgreeToTermsAnalyticsEventParams = [
  'CustomEvent',
  {
    eventCategory,
    action: EventAction.AgreeToTerms,
    eventLabel: origin,
  },
] as [ITrackEventName, Record<string, any>];

export const StartPayment = [
  'StartPayment' as ITrackEventName,
  {
    origin,
  },
] as [ITrackEventName, Record<string, any>];

export const AddPaymentInfo = ({activePaymentMethod}: {activePaymentMethod?: string}) =>
  [
    'AddPaymentInfo' as ITrackEventName,
    {
      origin,
      option: activePaymentMethod,
    },
  ] as [ITrackEventName, Record<string, any>];

export const trackInitiateCheckout = ({checkout}: {checkout: CheckoutModel}) => {
  const productsInfo = checkout.lineItems.map((item) => ({
    id: item.catalogItemId,
    name: item.productName,
    category: 'All Products',
    price: item.prices.lineItemPrice?.amount,
    currency: checkout.currency,
    quantity: item.quantity,
  }));

  return [
    'InitiateCheckout' as ITrackEventName,
    {
      contents: productsInfo,
      origin: 'Stores',
    },
  ] as [ITrackEventName, Record<string, any>];
};
