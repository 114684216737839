import {FormControllerAPI} from '@wix/form-viewer';
import {CheckoutSettingsModel} from '../../models/checkout/checkoutSettings/CheckoutSettings.model';
import {CustomFieldModel} from '../../models/checkout/CustomField.model';
import {CustomerDetailsFormSection, FieldPropertiesByTarget, FormFieldTarget} from '../../../types/checkoutApp.types';
import {AddressWithContactModel} from '../../models/checkout/AddressWithContact.model';
import {FORMS_TEMPLATE_IDS} from '../../../components/Checkout/constants';
import {SiteStore} from '@wix/wixstores-client-storefront-sdk';
import {
  getBillingFormInitialState,
  getBillingFormOverrides,
} from '../../../components/Checkout/Form/BillingForm/BillingForm.utils';
import {CheckoutSettingsService} from './CheckoutSettingsService';
import {FormValues} from '@wix/form-fields';
import {type CountryCode} from '@wix/form-multiline-address';
import {FormOverrides} from '@wix/form-viewer/dist/types/services/form-overrides';
import {
  getCustomerDetailsFormInitialState,
  getCustomerDetailsFormOverrides,
} from '../../../components/Checkout/Form/CustomerDetailsForm/CustomerDetailsForm.utils';
import {MemberService} from './MemberService';
import {CheckoutModel} from '../../models/checkout/Checkout.model';
import {CashierMandatoryFieldsOverrides} from '../../../types/payment.types';
import {SPECS} from '../../../common/constants';

export class FormsService {
  public validateForm: FormControllerAPI['validateForm'];
  private validationFunctionResolve!: (validateForm: FormControllerAPI['validateForm']) => void;
  private readonly siteStore: SiteStore;
  private readonly checkoutSettingsService: CheckoutSettingsService;
  private readonly memberService: MemberService;
  public isExtendedFieldsFormValid?: boolean;
  public shouldSubscribe: boolean | null = null;
  public isSubscriptionCheckboxCheckedByDefault: boolean | null = null;
  public doesFormHaveSubscription: boolean = false;
  public shippingDetailsFieldsProps: FieldPropertiesByTarget | null = null;
  public extendedFieldsTargets: string[] | null = null;

  constructor({
    siteStore,
    checkoutSettingsService,
    memberService,
  }: {
    siteStore: SiteStore;
    checkoutSettingsService: CheckoutSettingsService;
    memberService: MemberService;
  }) {
    const validationFunctionPromise = new Promise<FormControllerAPI['validateForm']>((resolve) => {
      this.validationFunctionResolve = resolve;
    });

    this.validateForm = async (...args) => {
      const validate = await validationFunctionPromise;
      return validate(...args);
    };

    this.siteStore = siteStore;
    this.checkoutSettingsService = checkoutSettingsService;
    this.memberService = memberService;
  }

  public setFormValidator = (validateForm: FormControllerAPI['validateForm']) => {
    this.validationFunctionResolve(validateForm);
  };

  public setShouldSubscribe(shouldSubscribe?: boolean) {
    this.shouldSubscribe = Boolean(shouldSubscribe);
  }

  public setIsSubscriptionCheckboxCheckedByDefault(isSubscriptionCheckboxCheckedByDefault: boolean) {
    if (this.isSubscriptionCheckboxCheckedByDefault === null) {
      this.isSubscriptionCheckboxCheckedByDefault = isSubscriptionCheckboxCheckedByDefault;
      this.setShouldSubscribe(isSubscriptionCheckboxCheckedByDefault);
    }
  }

  public setShippingDetailsFieldsProps = (properties: FieldPropertiesByTarget | null | undefined) => {
    this.shippingDetailsFieldsProps = properties ?? /* istanbul ignore next */ null;

    if (
      this.shippingDetailsFieldsProps &&
      this.siteStore.experiments.enabled(SPECS.UseNewSubscriptionCheckboxInCustomerDetailsForm)
    ) {
      this.doesFormHaveSubscription =
        Object.keys(this.shippingDetailsFieldsProps).includes(FormFieldTarget.subscribe) ??
        /* istanbul ignore next */ false;
    }
  };

  public setExtendedFieldsTargets = (dataExtendedFieldsProperties?: FieldPropertiesByTarget | null) => {
    this.extendedFieldsTargets = dataExtendedFieldsProperties
      ? Object.keys(dataExtendedFieldsProperties)
      : /* istanbul ignore next */ [];
  };

  public setExtendedFieldsFormValid = async (checkout: CheckoutModel, isFastFlow: boolean) => {
    const shouldUseTemporaryTemplate = this.siteStore.experiments.enabled(SPECS.MigrateSubscriptionToCheckoutForm);
    /* istanbul ignore next */
    const shouldHideEmailOnForm =
      this.siteStore.experiments.enabled(SPECS.TestNewSubscriptionCheckboxLocation) &&
      this.checkoutSettingsService.checkoutSettings.isSubscriptionEnabled;
    const extendedFieldsErrors = await this.validateForm({
      formId: shouldUseTemporaryTemplate
        ? /* istanbul ignore next */ FORMS_TEMPLATE_IDS.CUSTOMER_DETAILS_W_SUBSCRIPTION
        : FORMS_TEMPLATE_IDS.CUSTOMER_DETAILS_WO_ADDITIONAL_INFO,
      values: getCustomerDetailsFormInitialState({
        checkoutSettings: this.checkoutSettingsService.checkoutSettings,
        extendedFields: checkout.extendedFields ?? undefined,
        customField: checkout.customField,
        isFastFlow,
        doesFormHaveSubscription: this.doesFormHaveSubscription,
      }) as FormValues,
      overrides: getCustomerDetailsFormOverrides({
        checkoutSettings: this.checkoutSettingsService.checkoutSettings,
        excludeGoogleAutoComplete: this.siteStore.experiments.enabled(SPECS.CheckoutExcludeGoogleAutoComplete),
        isShippingFlow: false,
        isFastFlow: true,
        isMember: this.memberService.isMember(),
        formSections: [CustomerDetailsFormSection.extendedFields, CustomerDetailsFormSection.additionalInfo],
        shouldTestNewSubscriptionCheckboxLocation: shouldHideEmailOnForm,
      }) as FormOverrides,
    });
    this.isExtendedFieldsFormValid = !extendedFieldsErrors?.errors.length;
  };

  public isAddressValidForBilling = async (
    addressWithContact: AddressWithContactModel,
    {
      checkoutSettings,
      cashierMandatoryFields,
      useUndefinedAsDefaultBillingAddressInCheckout,
    }: {
      checkoutSettings: CheckoutSettingsModel;
      cashierMandatoryFields: CashierMandatoryFieldsOverrides;
      useUndefinedAsDefaultBillingAddressInCheckout: boolean;
    }
  ): Promise<boolean> => {
    const billingFormValidator = () => {
      const overrides = getBillingFormOverrides({
        cashierMandatoryFields,
        checkoutSettings,
        shippingDetailsFieldsProps: this.shippingDetailsFieldsProps ?? /* istanbul ignore next */ {},
        excludeGoogleAutoComplete: this.siteStore.experiments.enabled(SPECS.CheckoutExcludeGoogleAutoComplete),
        country: addressWithContact.address?.country as CountryCode,
      }) as FormOverrides;

      return this.validateForm({
        formId: FORMS_TEMPLATE_IDS.BILLING,
        values: getBillingFormInitialState({
          contact: addressWithContact.contact,
          initialAddress: addressWithContact.address,
          billingContact: addressWithContact.contact,
          useUndefinedAsDefaultBillingAddressInCheckout,
        }),
        overrides,
      });
    };

    return validateAllForms([billingFormValidator]);
  };

  public isAddressValidForShipping = async (
    addressWithContact: AddressWithContactModel,
    {
      checkoutSettings,
      customField,
      isShippingFlow,
      isFastFlow,
    }: {
      checkoutSettings: CheckoutSettingsModel;
      customField?: CustomFieldModel;
      isShippingFlow: boolean;
      isFastFlow: boolean;
    }
  ): Promise<boolean> => {
    const shouldUseTemporaryTemplate = this.siteStore.experiments.enabled(SPECS.MigrateSubscriptionToCheckoutForm);
    const shouldHideEmailOnForm =
      this.siteStore.experiments.enabled(SPECS.TestNewSubscriptionCheckboxLocation) &&
      this.checkoutSettingsService.checkoutSettings.isSubscriptionEnabled;
    const customerDetailsValidator = () =>
      this.validateForm({
        formId: shouldUseTemporaryTemplate
          ? /* istanbul ignore next */ FORMS_TEMPLATE_IDS.CUSTOMER_DETAILS_W_SUBSCRIPTION
          : FORMS_TEMPLATE_IDS.CUSTOMER_DETAILS_WO_ADDITIONAL_INFO,
        values: getCustomerDetailsFormInitialState({
          checkoutSettings,
          contact: addressWithContact.contact,
          address: addressWithContact.address,
          customField,
          shouldSubscribe: this.shouldSubscribe,
          isFastFlow,
          doesFormHaveSubscription: this.doesFormHaveSubscription,
        }) as FormValues,
        overrides: getCustomerDetailsFormOverrides({
          checkoutSettings,
          excludeGoogleAutoComplete: this.siteStore.experiments.enabled(SPECS.CheckoutExcludeGoogleAutoComplete),
          isShippingFlow,
          isMember: this.memberService.isMember(),
          formSections: [
            CustomerDetailsFormSection.contact,
            CustomerDetailsFormSection.subscription,
            CustomerDetailsFormSection.address,
            CustomerDetailsFormSection.additionalInfo,
          ],
          shouldTestNewSubscriptionCheckboxLocation: shouldHideEmailOnForm,
        }) as FormOverrides,
      });

    return validateAllForms([customerDetailsValidator]);
  };
}

async function validateAllForms(formValidations: (() => Promise<{errors: any[]} | undefined> | undefined)[]) {
  const validationErrors = await Promise.all(formValidations.map((validateForm) => validateForm()));
  const hasAnyErrors = validationErrors.some(
    (validationRes) => validationRes?.errors?.length && validationRes?.errors?.length > 0
  );

  return !hasAnyErrors;
}
