import {SiteStore} from '@wix/wixstores-client-storefront-sdk';
import {CheckoutService} from '../../services/checkout/CheckoutService';
import {NavigationService} from '../../services/checkout/NavigationService';
import {createSlotVeloAPIFactory} from '@wix/widget-plugins-ooi/velo';
import {IWidgetControllerConfig} from '@wix/native-components-infra';
import {SlotId} from '../../utils/slotId';
import {StepsManagerService} from '../../services/checkout/StepsManagerService';
import {SlotStoreProps, StepId} from '../../../types/checkoutApp.types';
import {PaymentMethod} from '@wix/cashier-payments-widget';
import {BIService} from '../../services/checkout/BIService';

interface CheckoutSlotApi {
  isMethodSupported: (methodName: string) => boolean;
  checkoutId: string;
  stepId: StepId | null;
  onRefreshCheckout: (callback: () => Promise<void>) => void;
  slotId: SlotId;
  paymentId?: PaymentMethod;
}

interface DeliveryMethodStepCheckoutSlotApi extends CheckoutSlotApi {
  deliveryMethodId?: string;
  onSetDeliveryMethodId: (callback: (deliveryMethodId: string) => Promise<void>) => void;
  onGoToNextStep: (callback: () => void) => void;
}

export class SlotsStore {
  private readonly biService: BIService;
  private readonly slotAPIFactory!: ReturnType<typeof createSlotVeloAPIFactory>;
  private readonly siteStore: SiteStore;
  private readonly checkoutService: CheckoutService;
  private readonly navigationService: NavigationService;
  private readonly stepsManagerService: StepsManagerService;
  private readonly updateComponent: () => void;

  constructor({
    controllerConfig,
    siteStore,
    checkoutService,
    updateComponent,
    navigationService,
    stepsManagerService,
    biService,
  }: {
    controllerConfig: IWidgetControllerConfig;
    siteStore: SiteStore;
    checkoutService: CheckoutService;
    updateComponent: () => void;
    navigationService: NavigationService;
    stepsManagerService: StepsManagerService;
    biService: BIService;
  }) {
    this.siteStore = siteStore;
    this.checkoutService = checkoutService;
    this.updateComponent = updateComponent;
    this.navigationService = navigationService;
    this.stepsManagerService = stepsManagerService;
    this.slotAPIFactory = createSlotVeloAPIFactory(controllerConfig);
    this.biService = biService;
  }

  /* istanbul ignore next: test slot */
  private readonly setSlotsParams = (slotId: SlotId): void => {
    let slotApi;
    const isDeliveryMethodStepSlotApi = slotId === SlotId.DeliveryMethodStep;
    if (isDeliveryMethodStepSlotApi) {
      slotApi = this.slotAPIFactory.getSlotAPI(slotId) as DeliveryMethodStepCheckoutSlotApi;
      slotApi.deliveryMethodId = this.checkoutService.checkout.selectedShippingOption?.code;
      slotApi.onSetDeliveryMethodId(async (deliveryMethodId: string) => {
        await this.checkoutService.setShippingOption(deliveryMethodId);
        this.updateComponent();
      });
      slotApi.onGoToNextStep(() => {
        this.biService.deliveryMethodSet(
          this.checkoutService.checkout,
          this.checkoutService.originalShippingTitle,
          true
        );
        this.stepsManagerService.goToNextStep({checkout: this.checkoutService.checkout});
        this.updateComponent();
      });
    } else {
      slotApi = this.slotAPIFactory.getSlotAPI(slotId) as CheckoutSlotApi;
    }

    slotApi.checkoutId = this.navigationService.checkoutId!;
    slotApi.slotId = slotId;

    slotApi.stepId = this.navigationService.isFastFlow
      ? StepId.paymentAndPlaceOrder
      : this.stepsManagerService.getActiveStep().stepId;

    slotApi.onRefreshCheckout(async () => {
      await this.checkoutService.fetchCheckout({update: true});
      this.updateComponent();
    });
  };

  /* istanbul ignore next: test slot */
  private readonly setStepId = (slotId: SlotId, stepId: StepId | null): void => {
    const slotApi = this.slotAPIFactory.getSlotAPI(slotId) as CheckoutSlotApi;
    slotApi.stepId = stepId ?? StepId.paymentAndPlaceOrder;
  };

  /* istanbul ignore next: test slot */
  private readonly setPaymentId = (slotId: SlotId, paymentId?: PaymentMethod): void => {
    const slotApi = this.slotAPIFactory.getSlotAPI(slotId) as CheckoutSlotApi;
    slotApi.paymentId = paymentId;
  };

  public toProps = (): SlotStoreProps => {
    return {
      setSlotsParams: this.setSlotsParams,
      setStepId: this.setStepId,
      setPaymentId: this.setPaymentId,
    };
  };
}
