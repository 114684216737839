import {CheckoutContent} from '@wix/ambassador-ecom-api-v1-checkout-content-provider/types';

export class CheckoutContentModel {
  public continueShoppingLink?: string;
  public orderSummary?: string;
  public customerDetails?: string;
  public reviewAndPlaceOrder?: string;
  public placeOrderButton?: string;
  public placeOrderPayButton?: string;

  constructor(checkoutContent?: CheckoutContent) {
    this.continueShoppingLink = checkoutContent?.continueShoppingLink ?? undefined;
    this.orderSummary = checkoutContent?.orderSummary ?? undefined;
    this.customerDetails = checkoutContent?.customerDetails ?? undefined;
    this.reviewAndPlaceOrder = checkoutContent?.reviewAndPlaceOrder ?? undefined;
    this.placeOrderButton = checkoutContent?.placeOrderButton ?? undefined;
    this.placeOrderPayButton = checkoutContent?.placeOrderPayButton ?? undefined;
  }
}
