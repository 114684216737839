import {PriceModel} from './Price.model';
import {MultiCurrencyPriceFragment} from '../../../gql/graphql';

export class ItemPricesModel {
  public lineItemPrice: PriceModel;
  public comparePrice?: PriceModel;
  public isLongPrice: boolean;
  constructor({
    lineItemPrice,
    price,
    fullPrice,
    priceBeforeDiscounts,
  }: {
    lineItemPrice?: MultiCurrencyPriceFragment;
    price?: MultiCurrencyPriceFragment;
    fullPrice?: MultiCurrencyPriceFragment;
    priceBeforeDiscounts?: MultiCurrencyPriceFragment;
  }) {
    this.lineItemPrice = new PriceModel(lineItemPrice);

    const hasSale = fullPrice?.formattedAmount !== price?.formattedAmount;
    const hasAutomaticDiscount = price?.formattedAmount !== priceBeforeDiscounts?.formattedAmount;

    this.comparePrice = hasSale ? new PriceModel(hasAutomaticDiscount ? priceBeforeDiscounts : fullPrice) : undefined;
    this.isLongPrice =
      this.lineItemPrice.formattedAmount.length > 12 || (hasSale && this.comparePrice!.formattedAmount.length > 12);
  }
}
