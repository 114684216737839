export const whitelistParams = ['currency', 'lang', 'dayful', 'headlessExternalUrls'];

export function addWhiteListParamsToUrl(url: URL, originalLocation: string) {
  const originalUrl = new URL(originalLocation);

  const params = whitelistParams
    .map((param) => ({param, value: originalUrl.searchParams.get(param)}))
    .filter(({value}) => !!value) as {param: string; value: string}[];

  params.forEach(({param, value}) => {
    url.searchParams.set(param, value);
  });

  return url;
}
