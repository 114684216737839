import {
  LocalDeliveryByZipCodeFlag,
  Payments,
  StoreCheckoutSettings,
  Destination,
} from '@wix/wixstores-graphql-schema-node';
import {ExtendedFieldsFragment, ApiAddressFragment} from '../../gql/graphql';
import {ApiAddress} from '../../types/checkoutApp.types';

export interface StoreCheckoutSettingsAndPayments {
  checkoutSettings: StoreCheckoutSettings;
  payments: Payments;
  localDeliveryByZipCodeFlag: LocalDeliveryByZipCodeFlag;
  destinations: Destination[];
}

export interface UserFieldsNamespaces {
  _user_fields: any;
}

export type ExtendedFieldNamespaces = Pick<ExtendedFieldsFragment, 'namespaces'> | UserFieldsNamespaces;

export interface UserExtendedFields {
  namespaces?: ExtendedFieldNamespaces;
}

export function isExtendedFieldNamespacesIsUserFields(
  namespaces: ExtendedFieldNamespaces
): namespaces is UserFieldsNamespaces {
  return (namespaces as UserFieldsNamespaces)._user_fields !== undefined;
}

export function isAddressWithGeoCode(address: ApiAddress): address is ApiAddressFragment {
  return (address as ApiAddressFragment).geocode !== undefined;
}
