import {ShowMandatoryTitle} from '@wix/wixstores-graphql-schema-node';

export class ShowMandatoryTitleModel {
  public show: boolean;
  public mandatory: boolean;
  public title: string;
  public untranslatedTitle: string;
  constructor(showMandatoryTitle?: ShowMandatoryTitle | null) {
    this.show = !!showMandatoryTitle?.show;
    this.mandatory = !!showMandatoryTitle?.mandatory && this.show;
    this.title = showMandatoryTitle?.title ?? '';
    this.untranslatedTitle = showMandatoryTitle?.untranslatedTitle ?? '';
  }
}
