/* istanbul ignore file */
export function mergeDeep(...objects: {[key: string]: object}[]) {
  const isObject = (obj: any) => obj && typeof obj === 'object';

  return objects.reduce((prev, obj) => {
    Object.keys(obj).forEach((key) => {
      const pVal = prev[key];
      const oVal = obj[key];

      if (Array.isArray(pVal) && Array.isArray(oVal)) {
        // eslint-disable-next-line  @typescript-eslint/no-unsafe-argument
        prev[key] = pVal.concat(...oVal);
      } else if (isObject(pVal) && isObject(oVal)) {
        prev[key] = mergeDeep(pVal as {[key: string]: object}, oVal as {[key: string]: object});
      } else {
        prev[key] = oVal;
      }
    });

    return prev;
  }, {});
}
