export type PaymentError = {
  translatedError?: string;
  failureDetails?: string;
  status: 'DECLINED' | 'BUYER_CANCELED';
};

export enum CashierMandatoryField {
  street = 'street',
  houseNumber = 'houseNumber',
  address = 'address',
  city = 'city',
  countryCode = 'countryCode',
  state = 'state',
  zipCode = 'zipCode',
  phone = 'phone',
}

export type CashierMandatoryFieldsOverrides = {
  [key in CashierMandatoryField]?: boolean;
};

export function isAPaymentError(obj: any): boolean {
  return !!obj && typeof obj !== 'string' && isAPaymentErrorType(obj);
}
export function isAPaymentErrorType(obj: any): obj is PaymentError {
  return 'status' in obj;
}
